
.dottedMiddleContainer{
    display: flex;
    justify-content: space-around;
}

.dottedMiddleContainer div:first-child{
    overflow: hidden;
    white-space: nowrap;
}

.dottedMiddleContainer div:first-child:after {
    content: ".........................."
}
  