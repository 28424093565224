.speedingWrapper{
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 15px;
    box-sizing: border-box;
}

.speedingDetail{
    font-size: 20px
}

.speedingDetail div:nth-child(2){
    font-size: 60px;
}

.speedingWrapper img{
    height: 120px;
}

.speedingButton{
    width: 130px;
    box-sizing: border-box;
    padding: 10px 15px;
}

@media (max-width: 600px) {
    .speedingWrapper{
        padding: 25px 5px;
        height: 130px;
        font-size: 13px;
    }

    .speedingDetail{
        font-size: 13px;
    }

    .speedingWrapper img{
        height: 70px;
    }
    
    .speedingDetail div:nth-child(2){
        font-size: 40px;
    }
    .speedingButton{
        width: 100px;
    }
}

@media (max-width: 440px) {

    .speedingWrapper{
        display: grid;
        grid-template-columns: 1fr 1fr;
    }
    .speedingWrapper > *{
        margin: auto;
    }
    .speedingWrapper img{
        display: none;
    }
}