@font-face {
  font-family: 'APPLE';
      src: local('APPLE'),
      url('./assets/fonts/APPLE.TTF') 
}

@font-face {
  font-family: 'san-francisco';
      src: local('SFUIText-BoldG1'),
      url('./assets/fonts/SFUIText-BoldG1.otf')
}

:root{
   --main-light-blue: #00cdfe;
  --main-white: white;
  --main-black: #000101 ;
  --main-red: #E93369;
}

*{
  font-family: 'APPLE';
  letter-spacing: -3px;
}

body {
  background-color: var(--main-black);
  color: var( --main-light-blue);
}
