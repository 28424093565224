.customBox{
    border: 2px solid var( --main-light-blue);
    border-radius: 15px;
    overflow: hidden;
    display: flex;
    align-items: center;
}

.customBoxContent{
    border: 2px solid var( --main-light-blue);
    border-radius: 15px;
    height: 100%;
    min-width: 0;/* important! */
    width: 100%;
    min-height: 90px;
}

.customBoxLine{
    width: 2px;
    height: 80px;
    margin: 2px;
}

.colorLines{
    margin: 5px;
    background-color: var(--main-light-blue);
}