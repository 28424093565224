.customModalWrapper{
    position: fixed;
    top: 0;
    height: 100vh;
    width: 100vw;
    box-sizing: border-box;
    background-color: rgba(0, 0, 0, 0.906);
    z-index: 1;
    display: flex;
    justify-content: start;
    align-items: center;
    flex-direction: column;
}

.customModalWrapper > *{
width: 500px;
margin-top: 30vh;
}

.customModalContent{
    min-height: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 20px;
}

.customModalFooter{
    margin-top: 10px;
    font-size: 12px;
}
.customModalButtonWrapper{
    display: flex;
}

.customModalButtonWrapper > * {
    margin: 10px;
}


@media (max-width: 700px){
    .customModalWrapper > *{
        max-width: 90vw;
        padding: 20px;
        box-sizing: border-box;
    }
}