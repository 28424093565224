.missionWrapper{
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 15px;
    box-sizing: border-box;
}

.missionDetail{
    font-size: 20px
}

.missionDetail div:nth-child(2){
    font-size: 60px;
}

.missionWrapper img{
    height: 120px;
}

.missionProgress{
    background: var(--main-light-blue);
    border-radius: 10px;
    color: var(--main-black);
    padding: 10px;
    width: 130px;
    box-sizing: border-box;
}

.progressBar{
    height: 15px;
    border: 2px solid var(--main-black);
    background-color: white;
    margin-bottom: 5px;
}

.progressBar > div{
    background-color: var(--main-light-blue);
    height: 100%;
    box-shadow: inset -15px 0px 0px -13px #000000;
    /* border-right: 2px solid var(--main-black); */
}
@media (max-width: 650px) {
    .missionWrapper img{
          height: 80px;
      }
  }
@media (max-width: 600px) {
    .missionWrapper{
        padding: 25px 5px;
        height: 130px;
        font-size: 13px;
    }

    .missionDetail{
        font-size: 13px;
    }

    .missionWrapper img{
        height: 60px;
    }
    
    .missionDetail div:nth-child(2){
        font-size: 40px;
    }
    .missionProgress{
        width: 100px;
        padding: 5px;
    }
}



@media (max-width: 440px) {
    .missionWrapper{
        display: grid;
        grid-template-columns: 1fr 1fr;
    }
    .missionWrapper > *{
        margin: auto;
    }
    .missionWrapper img{
        display: none;
    }
}